import React from "react"


const Footer = () => <footer id="site-footer"
                             className="content-box text-sm tracking-wider">
    <p>a <a className="underline" href="http://www.voidynullness.net">voidynullness</a> enterprise</p>
    <p>NULLIFIC PTY LTD</p>
    <p>ABN: 86 608 148 152</p>
</footer>;
export default Footer;
