import React from "react"


const Homepage = () => <div id="site-content" className="content-box tracking-widest p-6">
    <div id="intro" itemScope itemType="http://schema.org/Organization">
        <h1 itemProp="name" className="text-5xl font-black tracking-widest ">
            <span>NULLIFIC</span></h1>
        <p className="location tracking-normal font-black" itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
            <span itemProp="addressLocality">Melbourne</span> &nbsp; &bull; &nbsp;
            <span itemProp="addressCountry">Australia</span>
        </p>
        <hr/>
        <div id="intro-info">
            <p className="p-2">Mobile solutions</p>
            <p className="p-2">Web Applications</p>
            <p className="p-2">Custom software development</p>
        </div>
    </div>
</div>;
export default Homepage;
