import React from "react"

import HomePage from "../components/homepage"
import Footer from "../components/footer"


const IndexPage = () => <div id="content-wrap" className="font-body text-white bg-black text-center">
    <HomePage/>
    <Footer/>
</div>;
export default IndexPage;
